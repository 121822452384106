// extracted by mini-css-extract-plugin
export var Container = "n_L";
export var gridRowD = "n_M";
export var gridRowM = "n_N";
export var gridColD1 = "n_P";
export var gridColD2 = "n_Q";
export var gridColD3 = "n_R";
export var gridColD4 = "n_S";
export var gridColD5 = "n_T";
export var gridColD6 = "n_V";
export var gridColD7 = "n_W";
export var gridColD8 = "n_X";
export var gridColD9 = "n_Y";
export var gridColD10 = "n_Z";
export var gridColD11 = "n_0";
export var gridColD12 = "n_1";
export var gridColM1 = "n_2";
export var gridColM2 = "n_3";
export var aniSpin = "n_d";
// extracted by mini-css-extract-plugin
export var Footer = "s_by";
export var intro = "s_bz";
export var bodyText = "s_bB";
export var team = "s_bC";
export var team__heading = "s_bD";
export var team__list = "s_bF";
export var team__link = "s_bG";
export var nav = "s_bH";
export var nav__link = "s_bJ";
export var aniSpin = "s_d";
// extracted by mini-css-extract-plugin
export var Header = "w_b1";
export var inner = "w_p";
export var logo = "w_b2";
export var left = "w_b3";
export var right = "w_b4";
export var btnMenu = "w_b5";
export var nav = "w_bH";
export var isMenuOpen = "w_b6";
export var nav__list = "w_b7";
export var nav__item = "w_b8";
export var nav__link = "w_bJ";
export var aniSpin = "w_d";
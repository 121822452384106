// extracted by mini-css-extract-plugin
export var footer = "v_bQ";
export var message = "v_bR";
export var fields = "v_bS";
export var field = "v_bT";
export var fullWidth = "v_bV";
export var button = "v_bW";
export var label = "v_bX";
export var textInput = "v_bY";
export var textarea = "v_bZ";
export var select = "v_b0";
export var aniSpin = "v_d";
// extracted by mini-css-extract-plugin
export var Btn = "q_9";
export var inner = "q_p";
export var content = "q_x";
export var contentHover = "q_bb";
export var arrow = "q_bc";
export var leftArrow = "q_bd";
export var disabledArrow = "q_bf";
export var text = "q_bg";
export var textHover = "q_bh";
export var outline = "q_bj";
export var bg = "q_bk";
export var bgHover = "q_bl";
export var isDisabled = "q_bm";
export var aniSpin = "q_d";